<template>
  <div >
    <section class="mt-5">
        <b-carousel :interval="4000"
                    background="#ababab">
          <b-carousel-slide :img-src="isMobile ? 'https://activaciones.asistentemovistar.com.mx/banner_movil.jpg' : 'https://activaciones.asistentemovistar.com.mx/banner.jpg'">
          </b-carousel-slide>
        </b-carousel>
    </section>
    <br><br>
    <div class="container" style="color:#039cf3; cursor: pointer; font-size:16px;"   @click="$router.push('/')">
       <u>Regresar</u> 
    </div>
    
    <!-- <hr> -->
    <b-form @submit="store">
        <div class="row justify-content-center">
            <div class="col-md-12 col-11 mt-2" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                       <div class="row ">
                         <div class="col-md-12 mt-2">
                                <h5 class="text-center mt-5">Para iniciar selecciona el IDPDV al cual enviarás tu solicitud</h5>
                                <br>     
                         </div>    
                            <div class="col-md-4 mt-1">
                                 <label for="" class="text-secondary">Seleccione canal para empezar el registro</label>
                                <select class="form-control" v-model="canal" @change="traeRegion()" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                    <option value="" selected disabled>Canal</option>
                                    <option value="3" >Propio</option>
                                    <option value="2" >Especialista</option>
                                    <option value="1" >Retail</option>
                                </select>
                            </div>
                            <div class="col-md-4 mt-1">
                               <label for="" class="text-secondary">Líder</label>
                               <b-form-input placeholder="MRT" v-model="lider"   type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>                      
                            </div>
                            <div class="col-md-4 mt-1">
                               <label for="" class="text-secondary">Teléfono de contacto</label>
                               <b-form-input placeholder="Ingrese teléfono" v-model="telefono"   type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>        
                                <small v-if="errors.includes('telefono')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>              
                            </div>
                            <div class="col-md-12">
                                <hr style="border-top: 2px solid #CAF5A8 !important;  opacity :2;">
                            </div>
                            <!-- <div class="col-md-3 mt-1">
                               <label for="" class="text-secondary">Verifique su MRT</label>
                               <b-form-input placeholder="MRT" v-model="user.mrt"   type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>                      
                            </div> -->
                            <div class="col-md-3 mt-1">
                                <label for="" class="text-secondary">Seleccione región</label>
                                <v-autocomplete
                                v-model="region"
                                    clearable
                                    dense
                                    solo
                                    :items="arrayRegion"
                                    label="Región"
                                    @change="traePdv()"
                                    :disabled="canal == ''"   
                                ></v-autocomplete>
                                <small v-if="errors.includes('region')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>
                            </div>
                            <div class="col-md-3 mt-1">
                                <label for="" class="text-secondary">Seleccione IDPDV</label>
                                <v-autocomplete
                                v-model="pdv"
                                    clearable
                                    dense
                                    solo
                                    :items="arrayPdv"
                                    label="IDPDV"
                                    @change="traeAgencia()"
                                    :disabled="region == null"   
                                    
                                ></v-autocomplete>
                                <small v-if="errors.includes('pdv')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>
                            </div>
                            <div class="col-md-3 mt-1">
                                <label for="" class="text-secondary">Seleccione subdirector</label>
                               <select class="form-control" v-model="subdirector" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                    <option value="" selected disabled>Subdirector</option>
                                    <option v-for="sub in arraySub" :key="sub.id" :value="sub.subregional_nombre">{{sub.subregional_nombre}}</option>
                                </select>
                                <small v-if="errors.includes('subdirector')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>
                            </div>
                            <div class="col-md-3 mt-1">
                                <label for="" class="text-secondary">Seleccione agencia</label>
                                <select class="form-control" v-model="agencia"  style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;">
                                    <option value="" selected disabled>Agencia</option>
                                    <option v-for="ag in arrayAgencia" :key="ag.id" :value="ag.agencia">{{ag.agencia}}</option>
                                </select> 
                                <small v-if="errors.includes('agencia')" class="text-danger mt-2 text-left">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Este campo es obligatorio
                                </small>
                            </div>
                            <div class="col-md-3">
                                 <label for="" class="text-secondary">Calle</label>
                                 <b-form-input v-model="dire.calle"  type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>     
                            </div>
                            <div class="col-md-1">
                                 <label for="" class="text-secondary">Número</label>
                                 <b-form-input v-model="dire.numero" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>     
                            </div>
                            <div class="col-md-2">
                                 <label for="" class="text-secondary">Colonia</label>
                                 <b-form-input v-model="dire.colonia" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/> 
                            </div>
                            <div class="col-md-2">
                                 <label for="" class="text-secondary">CP</label>
                                 <b-form-input v-model="dire.cp" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/> 
                            </div>
                            <div class="col-md-2">
                                 <label for="" class="text-secondary">Ciudad </label>
                                 <b-form-input v-model="dire.ciudad" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/> 
                            </div>
                           <div class="col-md-2">
                                 <label for="" class="text-secondary">Municipio </label>
                                 <b-form-input v-model="dire.estado" type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/> 
                            </div>
                            <!-- <div class="col-md-12">
                                <br><br>
                                <h4 class="text-center">Detalles del Evento</h4>
                                <br>
                            </div> -->
                            <div class="col-md-3 mt-1">
                                <label for="" class="text-secondary">Nombre evento</label>
                                <b-form-input placeholder="Nombre Evento" v-model="evento"   type="text"  style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;"/>   
                                <small v-if="errors.includes('evento')" class="text-danger mt-2 text-left">
                                        <i class="fas fa-exclamation-circle"></i>
                                        Este campo es obligatorio
                                </small>
                                <br><br>
                            </div>
                            <div class="col-md-6" v-if="idedit">
                                <br>
                                <center>
                                    <DatePicker v-model="range" is-range   :min-date='new Date()' mode="dateTime">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center">
                                            <input
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                                class="border px-2 py-2 w-32 rounded focus:outline-none focus:border-indigo-300"
                                                placeholder="Fecha inicial evento"
                                                :style="!isMobile ? 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; width: 46%' :
                                                'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; width: 40%'"
                                            
                                            />
                                            <svg
                                                class="w-11 h-4 mx-20 ml-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                style="width: 5%;"
                                            >
                                                <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="3"
                                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                            <input
                                                :value="inputValue.end"
                                                class="border px-2 py-2 w-22 rounded focus:outline-none focus:border-indigo-300 ml-2"
                                                placeholder="Fecha final evento"
                                                :style="!isMobile ? 'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; width: 46%;' :
                                                'border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD; width: 40%'"
                                            />
                                            </div>
                                        </template>
                                        </DatePicker>
                                    <small v-if="errors.includes('fecha')" class="text-danger mt-2 text-left">
                                            <i class="fas fa-exclamation-circle"></i>
                                            Estos campos son obligatorios
                                    </small>
                                </center>
                            <br>
                            <br>
                           </div>
                           <div class="col-md-6 mt-1" v-else>
                            <div class="row">
                                <div class="col-md-6">
                                   <label for="" class="text-secondary">Fecha inicial</label>
                                   <b-form-input :value="range.start | fechaA"   type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;" disabled/>  
                                </div>
                                <div class="col-md-6">
                                   <label for="" class="text-secondary">Fecha final</label>
                                   <b-form-input :value="range.end | fechaA"   type="text" style="border-radius: 6px;box-shadow: inset 0 0 10px 0 #CDCDCD;" disabled/>  
                                </div>
                            </div>
                           </div>
                           <div class="col-md-3" v-if="!idedit">
                            <br>
                               <b-button   variant="secondary" pill    id="inde"  @click="idedit = 1">
                                    Editar fechas
                               </b-button>
                           </div>
                           <div class="col-md-3" v-else>
                               <br>
                               <b-button   variant="secondary" pill    id="inde"  @click="traerEdit(1)">
                                    Listo
                               </b-button>
                           </div>
                       </div>
                   </div>
                 </div>
            </div>
            <div class="col-md-4">
                <br>
                <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                <center>
                 <b-button 
                   variant="primary" pill  style="margin-top: -67px; width: 30%;" id="inde"  @click="modalServicio()">
                     <i class="far fa-plus"></i> Agregar servicio
                 </b-button>
                </center>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-8 col-7"></div>
            <div class="col-md-3 col-5">
                <b-button variant="success" block style="background: #CAF5A8; border-color: #CAF5A8; color:#000;" pill ><b>Costo TOTAL:</b> ${{ formatPrice(totalNeto)}} </b-button>
            </div>
            <div class="col-md-8">
            <center>
                <small v-if="errors.includes('servicio')"  class="text-danger mt-3">
                    <i class="fas fa-exclamation-circle"></i>
                    Agregue servicios para la activación
                    
                </small>
            </center>
            <br>
             <v-card  elevation="17" outlined shaped  color="#FCFCFC">
              <v-card-text>
                <b-card bg-variant="primary" text-variant="white"   class="text-center"  no-body>
                    <b-card-text>
                        <h5>LISTADO DE SERVICIOS</h5>
                    </b-card-text>
                </b-card>
               <div class="table-responsive">
                   <table class="table mt-4 " >
                        <thead >
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Servicio</th>
                            <th scope="col" >Fecha</th>
                            <th scope="col" style="width:16%;">Hora inicio</th>
                            <th scope="col" style="width:16%;">Hora fin</th>
                            <th scope="col" style="width:11%;">Hora permitidas</th>
                            <th scope="col">Costo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(det, index) in arrayDetalle" :key="det.id" :class=" index % 2 == 1 ? '' : 'table-su'">
                            <td>
                                <b-button size="sm" class="ml-2" title="Eliminar" @click="eliminarDetalle(index)"
                                        variant="outline-danger">
                                    <i  class="fas fa-trash"></i>
                                </b-button>
                            </td>
                            <td>{{det.servicio}}</td>
                            <td>
                                <b-form-input type="date" v-model="det.fecha" formatter="dd-mm-yyyy" />
                            </td>
                            <td>
                                <b-form-input type="time" v-model="det.hora_inicio"  />
                            </td>
                            <td>
                                <b-form-input type="time" v-model="det.hora_fin"  />
                            </td>
                            <td>
                                <b-form-input v-if="det.permitido != null" v-model="det.permitido" placeholder="Horas" disabled />
                            </td>
                            <td class="table-secondary">${{formatPrice(det.costo)}}</td>
                        </tr>
                        </tbody>
                    </table>
               </div>
              </v-card-text>
             </v-card>
            </div>
        </div>
        <template>
            <div class="row justify-content-center" >
                <div class="col-md-4 mt-5">
                    <br>
                     <br><br>
                    <!-- <div class="row justify-content-center">
                            <div class="col-md-5 mt-5"> </div>
                            <div class="col-md-2 mt-5">     
                                <br>
                                  <v-btn block  color="primary" style="text-transform: none;" @click="modalServicio()"  v-if="this.range.start != null"   >
                                    <i class="far fa-plus"></i> Agregar Servicio
                                  </v-btn>
                            </div>
                            <div class="col-md-5 mt-5"> </div>
                            <div class="col-md-12 mt-4 text-center">
                             <br>
                                <small v-if="errors.includes('servicio')"  class="text-danger mt-3">
                                    <i class="fas fa-exclamation-circle"></i>
                                    Agregue servicios para la activación
                                </small>
                            <div class="row  justify-content-center">
                                <div class="col-md-4 mt-5" v-for="(det, index) in arrayDetalle" :key="det.id">
                                <v-card  class="mx-auto mt-5"  elevation="8">
                                <v-card-text>
                                    <div>{{det.categoria}}</div>
                                    <p class="text-h6 " style="color:#039cf3;">
                                       {{det.servicio}}
                                    </p>
                                    <div class="text--primary">
                                        <hr>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="">Fecha inicial</label>
                                                <b-form-input type="date" v-model="det.fecha"/>
                                            </div>
                                            <div class="col-md-6">
                                              <template v-if="det.horas != null">
                                                <label for="">Hora</label>
                                                <b-form-input v-model="det.horas"/>
                                              </template>
                                              <template v-else>
                                                <label for="">Fecha final</label>
                                                <b-form-input v-model="det.fecha_final"/>
                                              </template>
                                            </div>
                                        </div>  
                                    </div>
                                    <hr>
                                    <b-button  class="ml-2" title="Eliminar" @click="eliminarDetalle(index)"
                                                variant="outline-danger">
                                            <i  class="fas fa-trash"></i> 
                                        </b-button>
                                   
                                    <v-btn    color="teal accent-4 ml-2" style="  text-transform: none; color:#fff;">
                                        Costo: ${{formatPrice(det.costo)}}
                                    </v-btn>
                                    
                                </v-card-text>
                            </v-card>
                            </div>
                           </div>

                        </div>
                    </div> -->
                    <hr style="border-top: 5px solid #78F57D !important;  opacity :2;">
                    <center>
                     <b-button type="submit" variant="primary" pill  style="margin-top: -67px; width: 30%;" id="inde" >
                         <i class="fas fa-save"></i><span> Editar</span> <b-spinner small v-if="loader"></b-spinner>
                     </b-button>
                    </center>
                    <!-- <div class="col-md-12 mt-2">
                    <center> 
                        <br>
                          <button :disabled="loader" class="btn btn-primary float-center" type="submit">
                                <span v-if="!loader">
                                    <i class="fas fa-save"></i><span> Enviar</span>
                                </span>
                                <span v-else>
                                <b-spinner small></b-spinner> Guardando...
                                </span>
                            </button>
                    </center>
                       <br><br><br>                  
                    </div> -->
                     <br><br><br> 
                </div>
                <br><br><br>
            </div>
        </template>
    </b-form>


    <b-modal id="plusServ" title="SERVICIOS " size="lg" hide-footer header-close-content="Cerrar">
      <div class="row justify-content-center">
        <div class="col-md-9">
             <h6 class="text-center">Selecciona uno o varios servicios para su activación.</h6>
             <br>
             <div class="input-group">
               <input type="text" v-model="buscar" @keyup.enter="listarSer(1,buscar)" class="form-control input-nav-search" placeholder="Búsqueda.">
               <button   @click="listarSer(1,buscar)" class="btn btn-primary"><i class="bx bx-search-alt"></i> Buscar</button>
               <button   @click="limpiarRe()" class="btn btn-secondary">Limpiar</button>
             </div>

                <table class="table table-hover table-striped mt-3" >
                    <thead class="bg-success text-white">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Servicio</th>
                        <th scope="col">Costo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="serv in arrayServ" :key="serv.id">
                        <td>
                            <button class="btn btn-primary btn-sm" @click="agregarDetalle(serv)">
                               Agregar 
                            </button>
                        </td>
                        <td>{{serv.item}}</td>
                        <td>
                           <template v-if="agencia == 'JET'">
                                ${{formatPrice(serv.valor_jet)}}
                            </template> 
                            <template v-if="agencia == 'SEED'">
                                ${{formatPrice(serv.valor_seed)}}
                            </template>
                            <template v-if="agencia == 'BOX'">
                                ${{formatPrice(serv.valor_box)}}
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <nav  id="pagi">
                    <ul class="pagination justify-content-center ">
                        <li class="page-item" v-if="pagination.current_page > 1">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar)">Anterior</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar)" v-text="page"></a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar)">Siguiente</a>
                        </li>
                    </ul>
                </nav>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
// import DateRangePicker from 'vue2-daterange-picker'
// //you need to import the CSS manually
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment';
import Swal from 'sweetalert2'
// import VCalendar from 'v-calendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    name:"Registro",
    components: {   DatePicker },
     data() {
        return {
          isMobile: this.$mobile(),
          errors:[],
          loader:false,
          canal:"",
          axc:"",
          region:null,
          pdv:null,
          arrayRegion:[],
          arrayPdv:[],
          agencia:"",
          arrayAgencia:[],
          arrayServ:[],
          servicio:null,
          finds: [],
          subdirector:"",
          arraySub:[],
          horas:"",
          tipoEvento:"",
          arrayDetalle:[],
          objServ:{},
          nombre_pdv:"",
          evento:"",
          fecha_inicial:"",
          hora_inicial:"",
          fecha_final:"",
          hora_final:"",
          offset : 3,
          pagination : {
              'total' : 0,
              'current_page' : 0,
              'per_page' : 0,
              'last_page' : 0,
              'from' : 0,
              'to' : 0,
         },
         buscar:"",
         fechas:"",
         e6: 1,
         range: {
                start: null,
                end: null,
            },
        date: '',
        timezone: '',
        idedit:"",
        telefono:"",
        lider:"",
        borra:0,
        tienda:{
            lat:0,
            lng:0
        },
        dire:{
            numero:"",
            calle:"",
            colonia:"",
            estado:"",
            cp:"",
            ciudad:""
        }
        }
     },
    computed: {
        user() {
            return this.$store.getters.getInfoUser;
        },
        isActived: function(){
          return this.pagination.current_page;
        },
        //Calcula los elementos de la paginación
        pagesNumber: function() {
            if(!this.pagination.to) {
                return [];
            }
            
            var from = this.pagination.current_page - this.offset; 
            if(from < 1) {
                from = 1;
            }

            var to = from + (this.offset * 2); 
            if(to >= this.pagination.last_page){
                to = this.pagination.last_page;
            }  

            var pagesArray = [];
            while(from <= to) {
                pagesArray.push(from);
                from++;
            }
            return pagesArray;
        },
        totalNeto: function() {
            var resultado = 0;
            for (var i = 0; i < this.arrayDetalle.length; i++) {
                resultado = resultado + parseInt(this.arrayDetalle[i].costo);
            }
            return resultado;
        }
    }, 
    filters: {
       dateCell (value) {
        let dt = new Date(value)

        return dt.getDate()
        },
        date (val) {
        return val ? val.toLocaleString() : ''
        },
        fechaA:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD h:mm:ss a')
            }
        }
    },
    methods:{
        traeRegion(){
          this.region = null;
          this.pdv = null;
          this.agencia = "";
          var url= 'activaciones/region?canal='+ this.canal;
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayRegion = data.data.region;
                        // this.arrayServ = data.data.servicio;
                    }
                );
        },
        traePdv(){
        //   this.region = id;
          this.pdv = null;
          var url= 'activaciones/pdv?canal='+ this.canal  + '&region='+ this.region;
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayPdv = data.data.pdv;
                    }
                );
        },
        traeAgencia(){
        //   this.pdv = id.pd;
        //   this.nombre_pdv = id.idpdv;
          var url= 'activaciones/agencia?region='+ this.region + '&canal='+ this.canal + '&pdv='+ this.pdv;
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayAgencia = data.data.agencia;
                        this.subdirector = data.data.sub;
                        this.arraySub = data.data.subdirector;
                        this.traeLocal();
                    }
                );
        },
        traeLocal(){
          var long = "";
          this.$api.get("admin/testigos/localizacion/" + this.pdv.replace(/[^0-9]+/g, "")).then(
                  ({data}) => {
                      this.tienda.lat = parseFloat(data.data.lat);
                      long = data.data.lng;
                      if (long.charAt(0) == '-') 
                      {
                        this.tienda.lng =  parseFloat(data.data.lng);
                      }else{
                         this.tienda.lng = '-' + parseFloat(data.data.lng);
                      }
                      this.usaGeo();
                    }
                );
        },
        usaGeo(){
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.tienda.lat + ',' + this.tienda.lng +'&key=AIzaSyDi1peq0RBC6zLi9a8gnfCESmtCEVHBbWE';
            fetch(url)
            .then(response => response.json())
            .then(data => {                 
                  for (let i = 0; i < data.results[1].address_components.length; i++)
                  {
                        if (data.results[1].address_components[i].types[0] == 'street_number')
                        {
                            this.dire.numero = data.results[1].address_components[i].long_name;
                        }      
                        if (data.results[1].address_components[i].types[0] == 'route')
                        {
                            this.dire.calle = data.results[1].address_components[i].long_name;
                        }          
                        if (data.results[1].address_components[i].types[0] == 'political')
                        {
                            this.dire.colonia = data.results[1].address_components[i].long_name;
                        }
                        if (data.results[1].address_components[i].types[0] == 'administrative_area_level_1')
                        {
                            this.dire.estado = data.results[1].address_components[i].long_name;
                        }
                        if (data.results[1].address_components[i].types[0] == 'postal_code')
                        {
                            this.dire.cp = data.results[1].address_components[i].long_name;
                        }
                        if (data.results[1].address_components[i].types[0] == 'locality')
                        {
                            this.dire.ciudad = data.results[1].address_components[i].long_name;
                        }
                  }
            });
        },
        store(e){
            e.preventDefault();
            this.loader = true;
            if (!this.evento) {
                 this.loader = false;
                 this.errors.push('evento');  
            }if (this.arrayDetalle.length == 0){
                 this.loader = false;
                 this.errors.push('servicio');      
            }if (!this.pdv){
                 this.loader = false;
                 this.errors.push('pdv');  
            }if (!this.telefono){
                 this.loader = false;
                 this.errors.push('telefono');  
            }if (!this.region){
                 this.loader = false;
                 this.errors.push('region');  
            }if (!this.agencia){
                 this.loader = false;
                 this.errors.push('agencia');  
            }if (!this.subdirector){
                 this.loader = false;
                 this.errors.push('subdirector');  
            }if (this.range.start == null || this.range.end == null) {
                 this.loader = false;
                 this.errors.push('fecha');  
            }if(this.errors.length == 0){
                let model = this.prepareModel();
                this.$api.post("activaciones/update", model).then(
                response => {
                    this.loader = false;
                    if (response.data.val == 1)
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Activación ha sido rechazada.',
                            text: 'Por saldo insuficiente.'
                        })
                    }else{
                        Swal.fire(
                        "List@",
                        "Activación editada exitosamente.",
                        'success'
                        )
                        this.clearMode();
                        this.$router.push("/")
                    }
                        console.log(response)
                },
                error => {
                    this.loader = false;
                    if (error.api) {
                         this.errors = error.api;
                         this.$swal("Aviso", "Datos requeridos.", "warning")
                    }
                }
                )
             }
        },
        prepareModel() {
             var model = new FormData();
                model.append("idedit", this.$route.params.folio);
                model.append("email", this.email);
                model.append("mrt", this.user.mrt);
                model.append("canal", this.canal);
                model.append("region", this.region);
                model.append("pdv", this.pdv);
                model.append("nombre_pdv", this.nombre_pdv);
                model.append("agencia", this.agencia);
                model.append("subdirector", this.subdirector);
                model.append("detalle", JSON.stringify(this.arrayDetalle));
                model.append("perfil", this.user.perfiles[0].usuario_perfil_id);
                model.append("evento", this.evento);
                model.append("fecha_inicial", moment(this.range.start).format('YYYY-MM-DD')   );
                model.append("hora_inicial", moment(this.range.start).format('h:mm:ss a')  );
                model.append("fecha_final", moment(this.range.end).format('YYYY-MM-DD'));
                model.append("hora_final", moment(this.range.end).format('h:mm:ss a'));
                 model.append("telefono", this.telefono);
                model.append("calle", this.dire.calle);
                model.append("numero", this.dire.numero);
                model.append("colonia", this.dire.colonia);
                model.append("estado", this.dire.estado);
                model.append("cp", this.dire.cp);
                model.append("ciudad", this.dire.ciudad);
                return model;
        },
        clearMode(){
            // this.email = "";
            // this.mrt = "";
            this.canal = "";
            this.region = null;
            this.pdv = null;
            this.agencia = "";
            this.servicio = null;
            this.horas = "";
            this.arrayDetalle = [];
            this.subdirector = "";
            this.nombre_pdv = "";
            this.loader = false;
            this.fecha_inicial = "";
            this.fecha_final = "";
            this.hora_inicial = "";
            this.hora_final = "";
            this.fechas = "";
            this.evento = "";
            this.telefono = "";
        },
        traeServ(){
        //    console.log(id);
           var url= 'activaciones/servicio?id='+ this.servicio;
            this.$api.get(url).then(
                    ({data}) => {
                        this.objServ = data.data.servicio;
                    }
                );
            
        },
        agregarDetalle(item){
            var cost = "";
            // var tiempo = "";
            // var duracion = "";

            // if (this.tipoEvento == 1 && !this.horas) {
            //     this.errors.push('horas');  
            // }else if(this.tipoEvento == 2 && !this.fecha_inicio  && !this.fecha_fin){
            //     this.errors.push('fechas');  
            // }else{

                switch (this.agencia)
                {
                case 'JET':
                    cost = item.valor_jet;
                break;
                case 'SEED':
                    cost = item.valor_seed;  
                break;
                case 'BOX':
                    cost = item.valor_box;    
                break; 
                }

                // if (this.tipoEvento == 1)
                // {
                //     tiempo = 'HORAS';
                //     duracion = this.horas;
                // }else{
                //     tiempo = 'EVENTO';
                //     duracion = this.fecha_inicio + ' - ' + this.fecha_fin;
                // }

                this.arrayDetalle.push({
                                    id: item.id,
                                    servicio: item.item,
                                    categoria : item.nuevo_sub,
                                    costo: cost,
                                    fecha: moment(this.range.start).format('YYYY-MM-DD'),
                                    hora_inicio: "",
                                    hora_fin: "",
                                    permitido: item.hora,
                                });

                this.servicio = null;
                this.horas = "";
                this.fechas = "";
                this.errors = [];
            // }
        },
        eliminarDetalle(index){
            let me = this;
            me.arrayDetalle.splice(index, 1);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(",", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        modalServicio(){
          this.listarSer(1, this.buscar);
          this.$bvModal.show('plusServ'); 
        },
        listarSer(page,buscar){
         var url= 'activaciones/listPag?page=' + page  + '&buscar='+ buscar;
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayServ = data.data.servicio.data;
                        this.pagination = data.data.pagination;
                    }
                );
        },
        cambiarPagina(page,buscar){
          let me = this;
          //Actualiza la página actual
          me.pagination.current_page = page;
          //Envia la petición para visualizar la data de esa página
          me.listarSer(page,buscar);
        },
        limpiarRe(){
            this.buscar  = "";
            this.listarSer(1,'');
        },
        traerEdit(id){
          var obj = {};
          var url= 'activaciones/edit?id='+ this.$route.params.folio + '&borra='+ id;
            this.$api.get(url).then(
                    ({data}) => {
                      obj = data.data.act;
                      this.canal = obj.canal;
                      this.telefono = obj.telefono;
                      this.lider = obj.mrt;
                      this.traeRegion();
                      this.region = obj.region;
                      this.traePdv();
                      this.pdv = obj.idpdv + ' - ' + obj.nombre_pdv;                      
                      this.traeAgencia();
                      this.subdirector = obj.subdirector;
                      this.agencia = obj.agencia;
                      this.evento = obj.evento;
                      this.range.start = obj.fecha_inicial + ' ' + obj.hora_inicial;
                      this.range.end = obj.fecha_final + ' ' + obj.hora_final;
                      this.arrayDetalle = data.data.detalle;
                      this.idedit = "";
                      this.dire.calle = obj.calle;
                      this.dire.numero = obj.numero;
                      this.dire.colonia = obj.colonia;
                      this.dire.cp = obj.cp;
                      this.dire.estado = obj.estado;
                      this.dire.ciudad = obj.ciudad;
                    }
                );   
        }
    },
    mounted(){
        this.traerEdit(this.borra);
    }
}

 
</script>
<style scoped>
  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


  #inde{
    z-index: 5;
  }

  .table-su{
    background: #CAF5A8;
  }
</style>